import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ShadowOnlyModal } from '@hotelplan/components.common.modals';

const BookmarkOverlayModal = styled(ShadowOnlyModal).attrs({
  name: 'bookmark-overlay',
})({
  '.modal-close': {
    display: 'none',
  },
});

const FdrBookmarkModalPopupDesktopWrapper: React.FC<
  PropsWithChildren<{ open: boolean }>
> = ({ open, children }) => {
  return (
    <BookmarkOverlayModal show={open} title={''}>
      <div>{children}</div>
    </BookmarkOverlayModal>
  );
};

export default FdrBookmarkModalPopupDesktopWrapper;
